function objectWithoutProperties (obj, exclude) { var target = {}; for (var k in obj) if (Object.prototype.hasOwnProperty.call(obj, k) && exclude.indexOf(k) === -1) target[k] = obj[k]; return target; }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full overflow-x-auto",attrs:{"data-testid":"local-component-product-data-table"}},[_c('modal-action',{attrs:{"data-testid":"local-component-product-data-table__modal-status-variant","visible":Boolean(_vm.modalVariantProduct.variantId),"title":_vm.modalVariantProduct.title,"icon":'confirmation',"description":_vm.modalVariantProduct.description,"isDisabled":_vm.modalVariantProduct.isDisabled},on:{"cancel":_vm.resetModalVariantProduct,"action":_vm.onSetStatusVariant}}),_c('data-table-v-2',{attrs:{"data-testid":"local-component-product-data-table__list","idIndex":0,"headers":_vm.headers,"dataItems":_vm.dataItems,"isLoading":_vm.isLoading,"list-has-collapse":_vm.listHasCollapse,"title-collapse":"Product Variant List"},on:{"on-expand":_vm.onExpand},scopedSlots:_vm._u([{key:"4",fn:function(ref){
var data = ref.data;
return [_c('toggle',{attrs:{"data-testid":("local-component-product-data-table__event-toggle-" + (data.id)),"id":data.id,"checked":data.isActive,"circle-height-class":"h-3","circle-width-class":"w-3","body-height-class":"h-5","body-width-class":"w-8","body-active-class":"bg-bgGreen","body-inactive-class":"bg-gray-2","body-border-class":"border-0","circle-active-class":"bg-white","circle-inactive-class":"bg-white"},on:{"click":function (e) { return _vm.onSetStatusProduct(e, data); }}})]}},{key:"5",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"flex justify-between gap-2"},[_c('router-link',{staticClass:"self-center",attrs:{"data-testid":("local-component-product-data-table__event-edit-product-" + (data.id)),"to":{
            name: 'EditMerchantProduct',
            params: { id: data.merchantId, productId: data.id },
          }}},[_c('edit-icon',{attrs:{"fill":"#78787A"}})],1),(!_vm.isGuest)?_c('span',{staticClass:"cursor-pointer",attrs:{"data-testid":("local-component-product-data-table__event-delete-product-" + (data.id))},on:{"click":function($event){return _vm.onDeleteProduct(data.id)}}},[_c('trash-icon')],1):_vm._e()],1)]}},{key:"expand-collapse",fn:function(ref){
          var rest = objectWithoutProperties( ref, [] );
          var props = rest;
return _vm._l((_vm.listVariant),function(item,idx){return _c('div',{key:idx},[(props.id.value === item.id)?_c('div',[(item.isLoading && item.listVariant.length === 0)?_c('div',{staticClass:"flex justify-center my-4"},[_c('LoadingOverlay')],1):_vm._e(),(!item.isLoading && item.listVariant.length === 0)?_c('div',{staticClass:"text-center text-gray-3",attrs:{"data-testid":"local-component-product-data-table__data-not-found"}},[_vm._v(" Data Not Found ")]):_c('table-collapse',{attrs:{"product-id":item.id,"data-testid":"local-component-product-data-table__table-collapse-product-variant","data-testid-value":item.id,"data":item.listVariant},on:{"set-status-variant":function (variant) { return _vm.showModalVariant(idx, variant); }}})],1):_vm._e()])})}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }