





































import { Component, Vue, Watch } from 'vue-property-decorator'
import ProductDataTable from './components/ProductDataTable/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import ModalAction from '@/app/ui/components/Modal/ModalAction.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import controller from '@/app/ui/controllers/ProductController'
import { PRODUCT_PAGINATION } from '@/app/infrastructures/misc/Constants/pagination'
import { EventBusConstants, Utils } from '@/app/infrastructures/misc'
import { Product, VariantProducts } from '@/domain/entities/Product'
import { MODAL_PRODUCTS, HEADER_LIST_PRODUCTS } from '@/app/infrastructures/misc/Constants/product'
import { Dictionary } from 'vue-router/types/router'

interface TypeCustomStyle {
  minWidth?: string
  maxWidth?: string
}

interface TypeDataTable {
  customStyle?: TypeCustomStyle
  value?: string | number
  id?: number
  isActive?: boolean
  merchantId?: number
}

@Component({
  components: {
    ProductDataTable,
    PaginationNav,
    Button,
    ModalAction,
    LoadingOverlay
  },
})
export default class MerchanProductListPage extends Vue {
  isGuest = Utils.isGuest()
  controller = controller
  // Still Dummy
  productDataTable = <Array<TypeDataTable[]>>([])
  listHasChild = <boolean[]>([])
  listVariant = <VariantProducts[]>([])

  parameters = {
    perPage: PRODUCT_PAGINATION,
    page: 1,
    productName: undefined,
  }
  modalProduct = {
    id: 0,
    type: '',
    title: '',
    description: '',
    isDisabled: false
  }
  header = HEADER_LIST_PRODUCTS

  created(): void {
    if (this.$route.query instanceof Object) {
      const queries = this.$route.query as Record<string, never>
      this.parameters = {
        page: Utils.alwaysNumber(queries.page) || 1,
        perPage: Utils.alwaysNumber(queries.perPage) || PRODUCT_PAGINATION,
        productName: queries.productName || undefined,
      }
    }

    this.fetchProductList()
  }

  private updateProductDataTable(): void {
    const { id, type } = this.modalProduct
    this.listVariant.find((item) => (item.id === id) && (item.listVariant?.forEach((variant) => variant.isActive = type === 'activate')))

    this.productDataTable.forEach(element => (element[4].id === id) && (element[4].isActive = type === 'activate'))
  }

  private deleteProductDataTable(): void {
    const { id } = this.modalProduct
    const index = this.productDataTable.findIndex(item => item[4].id === id)
    this.productDataTable.splice(index, 1)
    this.listHasChild.splice(index, 1)
  }

  private fetchProductList(): void {
    controller.getProductList({
      merchantId: Number(this.$route.params.id),
      ...this.parameters,
    })
  }

  private resetModalProduct(): void {
    this.modalProduct = {
      id: 0,
      type: '',
      title: '',
      description: '',
      isDisabled: false
    }
  }

  private setModalProduct(id: number, type: 'activate' | 'deactivate' | 'delete'): void {
    this.modalProduct = {
      id: id,
      type: type,
      title: MODAL_PRODUCTS[type].title,
      description: MODAL_PRODUCTS[type].description,
      isDisabled: false
    }
  }

  private showDeleteConfirmaton(id: number): void {
    this.setModalProduct(id, 'delete')
  }

  private showModalUpdateStatus(e: Event, product: Product): void {
    e.preventDefault()
    const id = product.id || 0
    const type = product.isActive ? 'deactivate' : 'activate'
    this.setModalProduct(id, type)
  }

  private actionClick(): void {
    const { id, type } = this.modalProduct
    if (type === 'activate') {
      this.modalProduct.isDisabled = true
      controller.updateProductStatus({ id: id, isActive: true })
    } else if (type === 'deactivate') {
      this.modalProduct.isDisabled = true
      controller.updateProductStatus({ id: id, isActive: false })
    } else if (type === 'delete') {
      this.modalProduct.isDisabled = true
      controller.deleteProduct(Number(id))
    }
  }

  @Watch('parameters')
  onParamsChanged(val: Dictionary<string | (string | null)[] | null | undefined> | undefined): void {
    this.$router.replace({
      query: { ...val },
    })
  }

  @Watch('controller.productData')
  onProductData(dataProduct: Product[]): void {
    const rowsHasChild: boolean[] = []
    const result = dataProduct.map(product => {
      let prices = ''
      if (product.highestPrice) {
        prices = `${Utils.toRupiah(Number(product.lowestPrice))} - ${Utils.toRupiah(Number(product.highestPrice))}`
      } else {
        prices = `${Utils.toRupiah(Number(product.lowestPrice))}`
      }
      rowsHasChild.push(<boolean>product.hasChild)
      return [
        {
          value: product.id,
          customStyle: { maxWidth: '70px' }
        },
        {
          value: product.productName,
          customStyle: { minWidth: '250px', maxWidth: '250px', paddingRight: '4vw' }
        },
        {
          value: prices,
          customStyle: { minWidth: '220px', maxWidth: '220px' }
        },
        {
          value: product.stock === null ? '-' : product.stock,
          customStyle: { minWidth: '150px', maxWidth: '150px'}
        },
        {
          id: product.id,
          isActive: product.isActive,
          customStyle: { minWidth: '126px' },
        },
        {
          merchantId: product.merchantId,
          id: product.id
        }
      ]
    })
    this.listHasChild = rowsHasChild
    this.productDataTable = <Array<TypeDataTable[]>>result
  }

  @Watch('controller.variantProduct')
  onSetVariantList(variant: VariantProducts): void {
    const listIdVariant = new Set()

    if (variant && variant.id) {
      this.listVariant.push(<VariantProducts>variant)
      const result = this.listVariant.map((list) => {
        const isDuplicate = listIdVariant.has(list.id)
        listIdVariant.add(list.id)
        let variantProduct = null
        if (list.id === variant.id) {
          if (!isDuplicate) {
            variantProduct = variant
          }
        } else {
          variantProduct = list
        }
        return variantProduct
      })
      this.listVariant = <VariantProducts[]>result.filter((item) => item)
    }
  }

  @Watch('controller.statusDeleteProduct', { deep: true })
  onStatusDeleteProductChanged(status: string): void {
    if (status !== '' && status === EventBusConstants.DELETE_PRODUCT_SUCCESS) {
      this.$notify({
        title: 'Product Deletion',
        text: 'Product is successfully deleted',
        type: 'success',
        duration: 5000,
      })

      this.deleteProductDataTable()
      this.resetModalProduct()
    }
    controller.setStatusDeleteProduct('')
  }

  @Watch('controller.statusUpdateStatusProduct', { deep: true })
  onStatusUpdateStatusProductChanged(status: string): void {
    if (status !== '' && status === EventBusConstants.UPDATE_STATUS_PRODUCT_SUCCESS) {
      this.$notify({
        title: 'Product Status',
        text: 'Product Status is successfully updated',
        type: 'success',
        duration: 3000,
      })

      this.updateProductDataTable()
      this.resetModalProduct()
    }
    controller.setStatusUpdateStatusProduct('')
  }

  beforeDestroy(): void {
    controller.setStatusDeleteProduct('')
    controller.setStatusUpdateStatusProduct('')
  }
}
