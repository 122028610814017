






























































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import Toggle from '@/app/ui/components/Toggle/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import TableCollapse from './TableCollapse.vue'
import { DataObject, HeaderObject } from '@/app/ui/components/DataTableV2/type'
import {
  Product,
  VariantProduct,
  VariantProducts,
} from '@/domain/entities/Product'
import TrashIcon from '@/app/ui/assets/trash_icon_alt2.vue'
import EditIcon from '@/app/ui/assets/edit_icon.vue'
import { EventBus, EventBusConstants, Utils } from '@/app/infrastructures/misc'
import controller from '@/app/ui/controllers/ProductController'
import ModalAction from '@/app/ui/components/Modal/ModalAction.vue'
import { MODAL_VARIANT_PRODUCT } from '@/app/infrastructures/misc/Constants/product'

interface CustomStyle {
  minWidth: string
  maxWidth: string
}

interface DataExpand {
  customStyle: CustomStyle
  value: string | number
}

@Component({
  components: {
    DataTableV2,
    Toggle,
    TableCollapse,
    EditIcon,
    TrashIcon,
    LoadingOverlay,
    ModalAction,
  },
})
export default class ProductDataTable extends Vue {
  @Prop({ default: () => [] }) private headers!: Array<
    string[] | HeaderObject[]
  >
  @Prop({ default: () => [] }) private dataItems!: Array<
    DataObject[]
  >
  @Prop({ default: false }) private isLoading!: boolean
  @Prop({ default: () => [] }) private listHasCollapse!: Array<boolean[]>
  @Prop({ default: () => [] }) private listVariant!: Array<VariantProducts>

  isGuest = Utils.isGuest()
  modalVariantProduct = {
    index: 0,
    variantId: 0,
    title: '',
    description: '',
    isActive: false,
    isDisabled: false,
  }

  controller = controller

  private onExpand(data: DataExpand[], key: 'open' | 'close'): void {
    const idProduct = data[0].value
    if (key === 'open') {
      // Get Variant List
      controller.getVariantList(Number(idProduct))
    }
  }

  private updateListVariant(): void {
    const { index, variantId, isActive } = this.modalVariantProduct
    if (this.listVariant[index]?.listVariant) {
      this.listVariant[index].listVariant?.forEach(
        (item: VariantProduct) => {
          if (item.variantId === variantId) {
            item.isActive = !isActive
          }
        }
      )
    }

    const isHasActive = this.listVariant[index]?.listVariant?.find(
      (item: VariantProduct) => {
        return item.isActive === true
      }
    )
    const productId = this.listVariant[index]?.id
    this.dataItems?.forEach((item: DataObject[]) => {
      if (item[4].id === productId) {
        item[4].isActive = !!isHasActive
      }
    })
  }

  private onSetStatusProduct(e: Event, product: Product): void {
    this.$emit('set-status', e, product)
  }

  private onSetStatusVariant(): void {
    const { variantId, isActive } = this.modalVariantProduct
    if (variantId) {
      this.modalVariantProduct.isDisabled = true
      controller.updateVariantStatus({ id: variantId, isActive: !isActive })
    }
  }

  private onDeleteProduct(id: number): void {
    this.$emit('delete', id)
  }

  private showModalVariant(index: number, variant: VariantProduct): void {
    const { variantId, isActive } = variant
    this.modalVariantProduct = {
      index: index,
      variantId: Number(variantId),
      title: !isActive
        ? MODAL_VARIANT_PRODUCT.activate.title
        : MODAL_VARIANT_PRODUCT.deactivate.title,
      description: !isActive
        ? MODAL_VARIANT_PRODUCT.activate.description
        : MODAL_VARIANT_PRODUCT.deactivate.description,
      isActive: !!isActive,
      isDisabled: false,
    }
  }

  private resetModalVariantProduct(): void {
    this.modalVariantProduct = {
      index: 0,
      variantId: 0,
      title: '',
      description: '',
      isActive: false,
      isDisabled: false,
    }
  }

  @Watch('controller.statusUpdateProductVariantStatus')
  onStatusUpdateProductVariantStatusChange(status: string): void {
    if (status !== '' && status === EventBusConstants.UPDATE_STATUS_PRODUCT_VARIANT_SUCCESS) {
      this.$notify({
          title: 'Product Variant Status',
          text: 'Product Variant Status is successfully updated',
          type: 'success',
          duration: 5000,
        })

        this.updateListVariant()
        this.resetModalVariantProduct()
    }
    controller.setStatusUpdateStatusProductVariant('')
  }

  beforeDestroy(): void {
    controller.setStatusUpdateStatusProductVariant('')
  }
}
