











































import { Component, Vue, Prop } from 'vue-property-decorator'
import Toggle from '@/app/ui/components/Toggle/index.vue'
import { VariantProduct } from '@/domain/entities/Product'
import { Utils } from '@/app/infrastructures/misc'

@Component({
  components: {
    Toggle,
  },
})
export default class ExpandCollapse extends Vue {
  @Prop({ default: 0 }) private productId!: number
  @Prop({ default: () => [] }) private data!: Array<VariantProduct[]>

  private getVariant(variantValue1: string, variantValue2: string) {
    if (variantValue2) {
      return `${variantValue1 || ''} | ${variantValue2}`
    } else {
      return variantValue1 || ''
    }
  }

  private formatCurrency(number: number) {
    return `Rp${Utils.currencyDigit(number || 0)}`
  }


  private onSetStatusProduct(event: Event, variant: VariantProduct) {
    event.preventDefault()
    this.$emit('set-status-variant', variant)
  }
}
